import { graphql } from "gatsby";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import HeadingGradient from "../components/HeadingGradient";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import "../styles/page/pricing.scss";
import "../styles/page/glossary.scss";
import CustomLink from "../components/CustomLink";

const alphabets = "abcdefghijklmnopqrstuvwxyz".split("");

const HeroSection = ({ textColor, title, description }) => {
  return (
    <div className=" mx-auto lg:px-4 md:px-3 px-2">
      <div className="pb-4 lg:pb-12 md:pb-6">
        <div className="relative flex flex-col items-center text-center">
          <span className="uppercase font-manrope text-xs sm:text-base tracking-[1.2px] sm:tracking-[1.6px] font-medium text-purple-1500">
            Resources
          </span>
          <span className="pt-3">
            <HeadingGradient headingHTML={title} />
          </span>
          <div
            className=" space-y-4 text-gray-600 text-base font-normal w-[288px] xs:w-auto -tracking-[0.4px] sm:-tracking-[0.45px] check_list sm:text-lg pt-5"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

const Glossary = ({ data }) => {
  const _data = data?.prismicGlossaryPage?.data;
  const [_alphabets] = _data?.body?.filter(
    (v) => v.slice_type === "alphabet_"
  ) || [[]];
  const [selectedLetter, setSelectedLetter] = useState("");
  const [letters, setLetters] = useState(_alphabets);

  useEffect(() => {
    if (!selectedLetter) {
      setLetters(_alphabets);
    } else {
      const filtered = _alphabets.items?.filter(
        (letter) =>
          letter?.alphabet_letter?.text?.toUpperCase() ===
          selectedLetter.toUpperCase()
      );
      setLetters({ ...letters, items: filtered });
    }
  }, [selectedLetter]);

  return (
    <Layout>
      <section className="pt-36 sm:w-[648px] lg:w-[972px] mx-auto  ">
        <HeroSection
          title={_data?.page_title?.html}
          description={_data?.page_description?.text}
        />
      </section>
      <section className="px-5 mx-auto sm:px-6 max-w-7xl">
        <ul className="flex py-4 overflow-x-auto character_list">
          {alphabets.map((alphabet, index) => {
            const disabled = !Object.keys(
              _alphabets?.items?.find(
                (a) =>
                  a?.alphabet_letter?.text?.toLocaleUpperCase() ===
                  alphabet.toLocaleUpperCase()
              ) || {}
            )?.length;
            return (
              <li
                key={index}
                className={`px-1 min-w-[46px] max-w-[46px] flex-1`}
                onClick={() => {
                  setSelectedLetter(alphabet.toLocaleUpperCase());
                }}
              >
                <span
                  className={` ${
                    disabled &&
                    `!rounded-md !bg-white !text-gray-400/50 !cursor-not-allowed`
                  } `}
                >
                  {alphabet.toLocaleUpperCase()}
                </span>
              </li>
            );
          })}
        </ul>
      </section>

      {selectedLetter && (
        <div className="flex items-center justify-between max-w-6xl px-5 mx-auto my-16 sm:px-6 sm:columns-2">
          <h4>
            Showing: <b>{selectedLetter?.toLocaleUpperCase()}</b>
          </h4>
          <button
            onClick={() => {
              setSelectedLetter("");
            }}
            className="glossary-btn text-white bg-purple-500 shrink-0 disabled:bg-purple-500 rounded-full font-semibold border border-purple-500;"
          >
            Show All
          </button>
        </div>
      )}
      <section className="max-w-6xl px-5 mx-auto my-16 sm:px-6 columns-1 sm:columns-2">
        {letters?.items?.map((x, index) => {
          const list = x?.glossary_items?.text?.split("/ ");
          return (
            <>
              <section
                className="flex gap-6 pt-3 pb-8 group"
                key={`letters_${index}`}
              >
                <span
                  className={`glossary_character group-hover:bg-purple-500 group-hover:text-white transition-all uppercase ${
                    selectedLetter && `!bg-purple-500 !text-white`
                  }`}
                >
                  {x?.alphabet_letter?.text}
                </span>
                <ul className="glossary_list">
                  {list?.map((x, index) => {
                    const [text, link] = x.split(":");
                    const _link = link.replace(/\/+$/, "");
                    return (
                      <li key={index} className="hover:text-purple-500">
                        <CustomLink to={`/glossary${_link}/`}>
                          {text}
                        </CustomLink>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </>
          );
        })}
      </section>
    </Layout>
  );
};
export const query = graphql`
  query {
    prismicGlossaryPage {
      id
      data {
        body {
          ... on PrismicGlossaryPageDataBodyAlphabet {
            id
            slice_type
            items {
              alphabet_letter {
                text
              }
              glossary_items {
                text
              }
            }
          }
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_description {
          text
        }
        page_title {
          html
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicGlossaryPage?.data;

  return (
    <SEO
      title={_data?.meta_title?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default Glossary;
