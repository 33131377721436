import React from "react";

const HeadingGradient = ({ headingHTML }) => {
  return (
    <span
      className="glossary text-gray-2700 text-3.25xl sm:text-4.5xl font-manrope leading-[45px] sm:leading-12 font-semibold tracking-[-1.8px] sm:tracking-tightest_4"
      dangerouslySetInnerHTML={{ __html: headingHTML }}
    />
  );
};

export default HeadingGradient;
